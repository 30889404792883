import React from 'react'
import styles from '../styles/message.module.css'

const Message = ({Icon, content, style, children}) => (
    <div className={styles.message} style={style}>
        {
            Icon && (<Icon className={styles.icon}/>)
        }
        <p>
            {content}
        </p>
        {
            children
        }
    </div>
)

export default Message