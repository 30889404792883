import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import Layout from '../components/layout'
import PromptButton from '../components/promptButton'
import Input from '../components/input'
import Message from '../components/message'
import { Link } from 'gatsby'
import validator from 'validator'
import { EMPTY_FIELD, INVALID_EMAIL } from '../helpers/errors'
import styles from '../styles/index.module.css'
import inputstyles from '../styles/input.module.css'
import { navigate } from '@reach/router'
import IconDigest from "../assets/icon-digest.svg"

const Subscribe = () => {

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState(false)
    const [fetching, setFetching] = useState(false)
    let submitButtonRef = useRef(null)
    const [urlParams, setUrlParams] = useState(``);
    const [email, setemail] = useState(``);
    let [digests, setDigests] = useState([]);
    let [selectedDigests,setSelectedDigests] = useState()
  const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (typeof window !== `undefined`) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            if (urlParams) {
                setUrlParams(urlParams);
                if (urlParams.has("email")) {
                    setemail(urlParams.get("email"))
                }
            }
        }
        fetch('/.netlify/functions/listDigests', {
              method: 'GET',
          }
        )
      .then(response => response.json())
      .then(data => {
          if (data.Digests) {
            const options = data.Digests.map( a => { return { label: a.Title, value: a.RecordId } } )
            //  setDigests(data.Digests)
            setDigests(options)
          } else {
              setDigests({})
          }
      })
    }, [] );

    const handleChange = e => {
        form[e.target.name] = e.target.value
        setForm({...form})
    }
  function handleSelect(data) {
    setSelectedDigests(data);
  }
    const handleSubmit = () => {
      console.log(selectedDigests)
        const hasErrors = !form.email?.length || !validator.isEmail(form.email ?? '')
        setErrors(hasErrors)
        if(!hasErrors) {
            setFetching(true)
            fetch('/.netlify/functions/subscribeToDigest', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify({
                    email: form.email,
                    digests: selectedDigests
                })
            })
              .then(response => response.json())
              .then(data => {
                  if(data.success) {
                      //navigate('/weatherReport/?d=subscribe-success')
                    window.close()
                  } else {
                     // navigate('/weatherReport/?d=subscribe-fail')
                    console.log("subscribe failed")
                    setErrors(true)
                    setErrorMessage(" Not Subscribed. " + data?.error)
                  }



              })
        }
    }

    // useEffect(() => {
    //     submitButtonRef.fetching(fetching)
    // }, [fetching, submitButtonRef])

    return (
      <Layout>
          <div className={styles.form}>
              <h2 c>Subscribe</h2>
<div>              <label htmlFor="digests">Choose a Digest:</label>

              <Select name="digests" id="digests" options={digests}  value={selectedDigests} isMulti className={inputstyles.input}
                      disabled={false} onChange={handleSelect} >


              </Select>
</div>

              <label>E-mail
                  { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                  <Input type={'email'} name={'email'}  value={form.email ?? ''} error={errors && !form.email?.length? EMPTY_FIELD : errors && !validator.isEmail(form.email ?? '')? INVALID_EMAIL : undefined} onChange={handleChange}
                  value={form.email} />
              </label>
              <PromptButton ref={ref => submitButtonRef = ref} primary={true} handleClick={handleSubmit}>Subscribe</PromptButton>
              <hr/>
              <div className={styles.recoverLink}>
                  <Link to={`/`}>Back Home</Link>
              </div>
          </div>
        {errorMessage && (
          <p className="error"> {errorMessage} </p>
        )}
      </Layout>
    )
}

export default Subscribe
